<template>
  <section id="signIn">
    <div>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
      <v-dialog
        v-model="sign_in_dialog"
        max-width="400px"
        persistent
        transition="dialog-top-transition"
      >
        <v-card class="elevation-0">
          <v-toolbar dense class="elevation-0 gradient-bg" dark>
            <v-toolbar-title class="font-weight-light">{{
              currentTitle
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="SignInDialogEmit()"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <div class="pt-4" align="center" justify="center">
            <v-img max-width="175px" src="@/assets/logo.jpg"></v-img>
          </div>
          <v-card-text>
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-card-text>
                  <v-form ref="form" lazy-validation>
                    <v-text-field
                      maxlength="41"
                      dense
                      label="Email ID *"
                      class="FontSize"
                      autocomplete="off"
                      prepend-inner-icon="mdi-account"
                      :rules="[
                        (v) => !!v || 'Email is Required',
                        (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
                        (v) =>
                          (v && v.length < 50) ||
                          'Email must be less than 50 characters',
                      ]"
                      v-model="Login.EmailID"
                    ></v-text-field>
                    <v-text-field
                      maxlength="15"
                      autocomplete="off"
                      label="Password *"
                      class="FontSize"
                      :type="isPwd ? 'text' : 'password'"
                      @click:append="isPwd = !isPwd"
                      required
                      :append-icon="isPwd ? 'mdi-eye' : 'mdi-eye-off'"
                      prepend-inner-icon="mdi-lock"
                      :rules="[
                        (v) => !!v || 'Required',

                        (v) =>
                          (v && v.length < 15) ||
                          'Password must be less than 15 characters',
                        (v) =>
                          (v && v.length > 5) ||
                          'Password must be more than 5 characters',
                      ]"
                      v-model="Login.Password"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions class="py-0">
                  <v-checkbox
                    dense
                    class="my-0 ml-2"
                    v-model="rememberme"
                    label="Remember Me"
                    :disabled="Login.EmailID == '' || Login.Password === ''"
                  />
                  <v-spacer></v-spacer>
                  <v-btn
                    x-small
                    text
                    class="FontSize mb-4"
                    color="primary"
                    @click="step = 3"
                    >Forgot Password ?</v-btn
                  >
                </v-card-actions>
                <v-card-actions class="pt-0 pb-8 px-4">
                  <v-btn
                    class="gradient-bg"
                    block
                    :loading="loading"
                    tile
                    color="primary"
                    @click="ValidateLoginForm()"
                    >Login</v-btn
                  >
                </v-card-actions>
                <!-- <div class="text-center">------------OR-------------</div>
                <v-card-actions class="pt-4 pb-8 px-4">
                  <v-btn
                    class="gradient-bg"
                    block
                    outlined
                    tile
                    @click="step = 5"
                    >Login With OTP</v-btn
                  >
                </v-card-actions> -->
              </v-window-item>
              <v-window-item :value="2">
                <v-card-text>
                  <v-form ref="formCP" lazy-validation>
                    <v-text-field
                      maxlength="15"
                      class="BorderRadius FontSize"
                      dense
                      autocomplete="off"
                      label="Enter New Password *"
                      :type="isPwdNew ? 'text' : 'password'"
                      @click:append="isPwdNew = !isPwdNew"
                      required
                      :append-icon="isPwdNew ? 'mdi-eye' : 'mdi-eye-off'"
                      prepend-inner-icon="mdi-lock"
                      :rules="[
                        (v) => !!v || 'Required',

                        (v) =>
                          (v && v.length < 15) ||
                          'Password must be less than 15 characters',
                        (v) =>
                          (v && v.length > 5) ||
                          'Password must be more than 5 characters',
                      ]"
                      v-model="Password.NewPassword"
                    ></v-text-field>
                    <v-text-field
                      maxlength="15"
                      class="BorderRadius FontSize"
                      dense
                      autocomplete="off"
                      label="Confirm New Password *"
                      :type="isPwdConfirm ? 'text' : 'password'"
                      @click:append="isPwdConfirm = !isPwdConfirm"
                      required
                      :append-icon="isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                      prepend-inner-icon="mdi-lock"
                      v-model="Password.ConfirmPassword"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions class="pt-0 pb-8 px-4">
                  <v-btn
                    block
                    :loading="loading"
                    tile
                    color="primary"
                    @click="ValidateConfirmPasswordForm()"
                    >Login</v-btn
                  >
                </v-card-actions>
              </v-window-item>
              <v-window-item :value="3">
                <v-card-text>
                  <v-form ref="formOTP">
                    <div class="text-center" style="color: #0c9f6b">
                      Please Enter The Registered Email
                    </div>
                    <v-text-field
                      maxlength="41"
                      label="Email"
                      outlined
                      autocomplete="off"
                      v-model="OTPEmail"
                      :rules="[
                        (v) => !!v || 'Email is Required',
                        (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
                        (v) =>
                          (v && v.length < 50) ||
                          'Email must be less than 50 characters',
                      ]"
                      class="mt-4 FontSize"
                    >
                    </v-text-field>
                    <div class="FontSize text-center" style="color: #0c9f6b">
                      *Please enter the e-mail used for login/registration
                    </div>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="primary" small @click="step = 1"
                    >Back To Sign In</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" small @click="ValidateOTP()"
                    >Next</v-btn
                  >
                </v-card-actions>
              </v-window-item>
              <v-window-item :value="4">
                <v-card-text>
                  <v-form ref="formConfirmOTP">
                    <div class="text-center" style="color: #0c9f6b">
                      Enter the Activation Code
                    </div>
                    <v-text-field
                      label="Code *"
                      autocomplete="off"
                      v-model="ConfirmOTP"
                      :rules="[(v) => !!v || 'Code is required']"
                      class="mt-4 FontSize"
                    >
                    </v-text-field>
                    <v-text-field
                      maxlength="15"
                      autocomplete="off"
                      label="Enter New Password *"
                      v-model="ConfirmNewPassword"
                      :rules="[
                        (v) => !!v || 'Required',

                        (v) =>
                          (v && v.length < 15) ||
                          'Password must be less than 15 characters',
                        (v) =>
                          (v && v.length > 5) ||
                          'Password must be more than 5 characters',
                      ]"
                      class="mt-4 FontSize"
                      :type="isPwdConfirmCode ? 'text' : 'password'"
                      @click:append="isPwdConfirmCode = !isPwdConfirmCode"
                      required
                      :append-icon="
                        isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      prepend-inner-icon="mdi-lock"
                    >
                    </v-text-field>
                    <div class="FontSize text-center" style="color: #0c9f6b">
                      Enter the Activation Code sent to your registered Email.
                    </div>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="primary" small @click="step = 1"
                    >Back To Sign In</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    small
                    @click="ValidateEnteredOTP()"
                    >Next</v-btn
                  >
                </v-card-actions>
              </v-window-item>
              <v-window-item :value="5">
                <v-card-text>
                  <v-form ref="EmailOTP">
                    <div>Enter Registered Email*</div>
                    <v-text-field
                      dense
                      outlined
                      class="mt-4"
                      label="Email *"
                      v-model="Login_OTP_Email"
                      :rules="[
                        (v) => !!v || 'Email is Required',
                        (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
                        (v) =>
                          (v && v.length < 50) ||
                          'Email must be less than 50 characters',
                      ]"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions class="pt-0 pb-8 px-4">
                  <v-btn
                    class="gradient-bg"
                    block
                    :loading="Emailloading"
                    tile
                    color="primary"
                    @click="ValidateEmailOTPForm()"
                    >Request OTP</v-btn
                  >
                </v-card-actions>
                <v-card-actions class="pt-0 pb-8 px-4">
                  <v-btn block outlined tile @click="step = 1"
                    ><v-icon class="align-left">mdi-arrow-left</v-icon> Back To
                    signIn</v-btn
                  >
                </v-card-actions>
              </v-window-item>
              <v-window-item :value="6">
                <v-form ref="formOTPForm" lazy-validation>
                  <div class="py-2">
                    Enter OTP Sent to
                    <span class="font-weight-bold"
                      >{{ $store.getters.get_user_email }}
                      <v-btn small outlined @click="step = 5"
                        >Change</v-btn
                      ></span
                    >
                  </div>
                  <v-otp-input
                    dense
                    label=""
                    length="6"
                    @click:append="isPwdNew = !isPwdNew"
                    required
                    :rules="[(v) => !!v || 'Password is required']"
                    v-model="OTPRecieved"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="primary">mdi-lock</v-icon>
                    </template>
                  </v-otp-input>
                </v-form>
                <v-card-actions class="pa-0 pb-10 pt-4">
                  <v-btn
                    block
                    :loading="loadingPassword"
                    tile
                    color="primary"
                    @click="ValidateConfirmSignIn()"
                    >Login</v-btn
                  >
                </v-card-actions>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </section>
</template>
<script>
import { Auth } from "aws-amplify";
import SnackBar from "@/components/Extras/SnackBar.vue";
export default {
  props: {
    sign_in_dialog: Boolean,
  },
  components: {
    SnackBar,
  },
  data: () => ({
    step: 1,
    Login: {
      EmailID: "",
      Password: "",
    },
    ConfirmOTP: "",
    Login_OTP_Email: "",
    ConfirmNewPassword: "",
    OTPEmail: "",
    Emailloading: false,
    isPwdNew: false,
    isPwdConfirm: false,
    isPwdConfirmCode: false,
    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    isPwd: false,
    loading: false,
    rememberme: false,
    SnackBarComponent: {},
    OTPRecieved: "",
    loadingPassword: false,
  }),
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Sign In";
        case 2:
          return "Change Temporary Password";
        case 3:
          return "Forgot Password ?";
        case 4:
          return "Forgot Password ?";
        case 5:
          return "Sign Up";
        default:
          return "Account created";
      }
    },
  },
  watch: {
    rememberme(val) {
      if (val == true) {
        localStorage.setItem("currentuseremail", JSON.stringify(this.Login));
      } else if (val == false) {
        delete localStorage.currentuseremail;
      }
    },
  },
  mounted() {
    this.CheckRememberMe();
    this.$store.commit("SET_AUTH", false);
  },
  methods: {
    CheckRememberMe() {
      if (localStorage.getItem("currentuseremail") != null) {
        this.Login = JSON.parse(localStorage.getItem("currentuseremail"));
        this.rememberme = true;
      }
    },
    ConfirmOTPMethod() {
      Auth.forgotPasswordSubmit(
        this.OTPEmail,
        this.ConfirmOTP,
        this.ConfirmNewPassword
      )
        .then(() => {
          this.$refs.formConfirmOTP.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Password Successfully Changed ",
            timeout: 2000,
          };
          this.step = 1;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
            timeout: 2000,
          };
        });
    },
    ValidateEnteredOTP() {
      (this.OTPEmail = this.OTPEmail.trim()),
        (this.ConfirmOTP = this.ConfirmOTP.trim()),
        (this.ConfirmNewPassword = this.ConfirmNewPassword.trim());
      if (this.$refs.formConfirmOTP.validate()) {
        this.ConfirmOTPMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Code Sent To Registered Email",
          timeout: 2000,
        };
      }
    },
    ValidateOTP() {
      this.OTPEmail = this.OTPEmail.trim();
      if (this.$refs.formOTP.validate()) {
        if (this.OTPEmail != "" && this.OTPEmail != null) {
          this.SendOTPEmail();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Registered Email",
          timeout: 2000,
        };
      }
    },
    SendOTPEmail() {
      Auth.forgotPassword(this.OTPEmail)
        .then(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP Sent To Registered Email",
            timeout: 2000,
          };
          this.step = 4;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    async ConfirmPassword() {
      try {
        this.loading = true;
        const result = await Auth.completeNewPassword(
          this.user,
          this.Password.ConfirmPassword
        )
          .then((user) => {
            return user;
          })
          .catch((e) => {
            return e;
          });
        if (result.username) {
          this.$refs.formCP.reset();
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
            timeout: 2000,
          };
          this.ActivateMethod();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
          timeout: 2000,
        };
      }
    },
    ValidateConfirmPasswordForm() {
      this.Password.NewPassword = this.Password.NewPassword.trim();
      this.Password.ConfirmPassword = this.Password.ConfirmPassword.trim();
      if (this.$refs.formCP.validate()) {
        if (
          this.Password.NewPassword != "" &&
          this.Password.NewPassword != null &&
          this.Password.ConfirmPassword != "" &&
          this.Password.ConfirmPassword != null
        ) {
          if (this.Password.NewPassword == this.Password.ConfirmPassword) {
            this.ConfirmPassword();
          } else {
            this.$refs.form.reset();
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Password does not match",
              timeout: 2000,
            };
          }
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
          timeout: 2000,
        };
      }
    },
    ValidateLoginForm() {
      (this.Login.EmailID = this.Login.EmailID.trim()),
        (this.Login.Password = this.Login.Password.trim());
      if (this.$refs.form.validate()) {
        if (
          this.Login.EmailID != "" &&
          this.Login.EmailID != null &&
          this.Login.Password != "" &&
          this.Login.Password != null
        ) {
          this.SignIn();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
          timeout: 2000,
        };
      }
    },
    async SignIn() {
      try {
        this.overlay = true;
        this.loading = true;
        const user = await Auth.signIn(
          this.Login.EmailID.toLowerCase(),
          this.Login.Password
        );
        this.user = user;

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.step = 2;
          this.loading = false;
          this.$store.commit("SET_AUTH", false);
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID.toLowerCase());
          var LoginFirstTimeLoginStatus = true;
          this.$store.commit("SET_LOGIN_STATUS", LoginFirstTimeLoginStatus);
        } else {
          LoginFirstTimeLoginStatus = false;
          this.$store.commit("SET_AUTH", true);
          this.$store.commit("SET_LOGIN_STATUS", LoginFirstTimeLoginStatus);
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID.toLowerCase());
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "primary",
            Top: true,
            SnackbarText: "Login Successful",
            timeout: 2000,
          };
          this.ActivateMethod();
        }
      } catch (error) {
        this.overlay = false;
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
          timeout: 2000,
        };
      }
    },
    ActivateMethod() {
      setTimeout(() => this.$router.push("/Dashboard"), 1000);
      this.overlay = false;
    },
    SignInDialogEmit() {
      this.$emit("clicked");
    },
    ValidateEmailOTPForm() {
      this.Login_OTP_Email = this.Login_OTP_Email.trim();
      if (this.$refs.EmailOTP.validate()) {
        if (this.Login_OTP_Email != "" && this.Login_OTP_Email != null) {
          this.SignInOTPEmail();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    async SignInOTPEmail() {
      try {
        this.Emailloading = true;
        const clientMetadata = {
          suppressEmail: "true",
        };
        this.user = await Auth.signIn(this.Login_OTP_Email);
        this.$store.commit("SET_USEREMAIL", this.Login_OTP_Email.toLowerCase());
        if (this.user.challengeName === "CUSTOM_CHALLENGE") {
          this.OTPRecieved = "";
          this.step = 6;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP Sent Successfully!!",
          };
        }
        this.Emailloading = false;
      } catch (error) {
        console.log("error", error);
        this.Emailloading = false;
        if (
          error ==
          "UserLambdaValidationException: DefineAuthChallenge failed with error Error: NOT_AUTHORIZED : Kindly Sigup."
        ) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            // SnackbarText: error.message,
            SnackbarText: "Email not found",
          };
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            // SnackbarText: error.message,
            SnackbarText: "Invalid Credentials",
          };
        }
      }
    },
    ValidateConfirmSignIn() {
      if (this.$refs.formOTPForm.validate()) {
        this.confirmOTP();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    async confirmOTP() {
      try {
        this.loadingPassword = true;
        await Auth.sendCustomChallengeAnswer(this.user, this.OTPRecieved).then(
          (res) => {
            console.log("Check The Result", res);
            if (res.attributes.email == undefined) {
              this.loadingPassword = false;
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Invalid OTP Entered",
              };
              this.loadingPassword = false;
            } else {
              this.$store.commit("SET_AUTH", true);
              this.$store.commit("SET_LOGIN_STATUS", true);
              this.ActivateMethod();
              this.loadingPassword = false;
            }
          }
        );

        console.log("Hello");
      } catch (error) {
        console.log("errorrr", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
        this.loadingPassword = false;
      }
    },
  },
};
</script>
<style>
.gradient-btn {
  background: -webkit-linear-gradient(#0a876e 40%, #0fc566 60%);
  -webkit-text-fill-color: transparent;
}
.gradient-bg {
  background: -webkit-linear-gradient(145deg, #0fc566 0%, #0a876e 100%);
  background: -moz-linear-gradient(145deg, #0fc566 0%, #0a876e 100%);
  background: -o-linear-gradient(145deg, #0fc566 0%, #0a876e 100%);
  background: -ms-linear-gradient(145deg, #0fc566 0%, #0a876e 100%);
  background: linear-gradient(145deg, #0fc566 0%, #0a876e 100%);
}
.gradient-bg2 {
  background: -webkit-linear-gradient(145deg, #0a876e 0%, #0fc566 100%);
  background: -moz-linear-gradient(145deg, #0a876e 0%, #0fc566 100%);
  background: -o-linear-gradient(145deg, #0a876e 0%, #0fc566 100%);
  background: -ms-linear-gradient(145deg, #0a876e 0%, #0fc566 100%);
  background: linear-gradient(145deg, #0a876e 0%, #0fc566 100%);
}
</style>
