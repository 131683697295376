<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
export default {
  data: () => ({
    fab: null,
  }),

  methods: {},
};
</script>
<style>
/* ::-webkit-scrollbar {
  display: none;
}  */
slide-in-from-top {
  animation: slideInFromTop 1s ease-out;
}
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.slide-in-from-bottom {
  animation: slideInFromBottom 1s ease-out;
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.MaxWidthVariant1 {
  max-width: 150px !important;
}
.MaxWidthVariant2 {
  max-width: 300px !important;
}

.FontSize {
  font-size: 12px;
}
.FontSize10px {
  font-size: 12px !important;
  font-weight: bold !important;
}

.fontSize {
  font-size: 10px;
}
.fontSize {
  font-size: 10px;
}
.fontsize20px {
  font-size: 20px;
}
.v-input .v-label {
  font-size: 12px;
}
.field_height.v-text-field .v-input__control .v-input__slot {
  min-height: 12px !important;
  display: flex !important;
  align-items: center !important;
}
.field_label_size.v-input .v-label {
  font-size: 12px;
}
.v-select
  .v-input__control
  .v-input__slot
  .v-select__slot
  .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 14px 0 !important;
}
.maxWidthVariant1 {
  max-width: 200px !important;
}
.TableClass table {
  width: 150% !important;
}

.TableClass td {
  font-size: 12px !important;
}

.TableClass th {
  font-size: 10px !important;
}
/* body {
  overflow-y: hidden;
} */
.PointerCursor {
  cursor: pointer !important;
}

#content {
  height: calc(100vh - 65px);
  overflow-y: hidden;
}

.v-text-field .v-input_control .v-input_slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

/* .vdataTablescroll .v-data-table__wrapper::-webkit-scrollbar {
  width: 12px !important;
}

.vdataTablescroll .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: red !important;
  border-radius: 6px !important;
}

.vdataTablescroll .v-data-table__wrapper::-webkit-scrollbar-track {
  background-color: orange;
} */

/* Firefox */
* {
  scrollbar-width: thin !important;
  scrollbar-color: #0c9f6b #cdcdcd;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  height: 6px !important;
  width: 6px !important;
  scrollbar-color: #0c9f6b #cdcdcd;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  scrollbar-width: thin !important;
  background-color: #0c9f6b !important;
  border-radius: 10px;
  border: 0px solid #ffffff;
}
.TableDataClass td {
  font-size: 12px !important;
}
.MaxFieldWidth {
  max-width: 300px !important;
  height: 20px !important;
}
.SelectFieldWidth {
  max-width: 200px !important;
  height: 20px !important;
}
</style>
